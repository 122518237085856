<template>
 <div id="mapContainer"></div>
  <div>
    <Header></Header>
    <!-- 当前时间地区指标值 -->
    <div style="padding:10px 20px 0px 20px;">
      <el-row :gutter="20">
        <el-col :span="3" v-for="item in curRegionItemData" :key="item.id">
          <div class="col-d" :class="item.isSel?'selcol':''" @click="selItemFun(item.id)">
            <span class="txt" :title="item.name+(item?.unit?'('+item?.unit+')':'')">{{item.name+(item?.unit?'('+item?.unit+')':'')}}</span>
            <p class="number-text">
              <strong v-if='item.value!="-"'>
                <CountTo  :end='item.value' :autoPlay="true" :default='item.value.toString().split(".").length>1?2:0' :duration='3000' />
              </strong> 
              <strong v-else> {{item.value}}</strong>
            </p>
          </div>
        </el-col>
      </el-row>
      
    </div>
    <!-- 导航 -->
    <!-- <div class="nav_box">
       <svg class="icon" aria-hidden="true" style='font-size:1.5rem;height: 50px;line-height: 50px;vertical-align: top;margin-left: 10px;'>
        <use xlink:href="#icon-biaoqiankuozhan_dingwei-312"></use>
      </svg>
			<el-breadcrumb separator="/" separator-class="el-icon-arrow-right" >
				<el-breadcrumb-item>{{region.name}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{curRegionItemData.filter(t=>{return t.isSel==true})[0].name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
    <!-- 时间、地区列表 -->
     <div style="position:absolute;text-align:right;margin-top:20px;width: auto;left:20px;">
      <!-- 地区列表选择 -->
      <div style="margin-bottom:6px;display: inline-block">
      <el-popover popper-class="region-popper"
       
        :width="400"
        trigger="click"
        v-model:visible="regionListVisible">
        <template #reference>
          <el-button type="primary"  icon="el-icon-location">
           {{region.name}}
          </el-button>
        </template>
        <div>
          <div class="city_content_medium">
            <div>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("110000","北京市","0")'>北京市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("120000","天津市","0")'>天津市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("310000","上海市","0")'>上海市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("500000","重庆市","0")'>重庆市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("330100","杭州市","330000")'>杭州市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("320100","南京市","320000")'>南京市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("430100","长沙市","430000")'>长沙市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("420100","武汉市","420000")'>武汉市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("610100","西安市","610000")'>西安市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("370200","青岛市","370000")'>青岛市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("340100","合肥市","340000")'>合肥市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("410100","郑州市","410000")'>郑州市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("320500","苏州市","320000")'>苏州市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("370100","北京市","")'>济南</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("120000","天津市","0")'>大连</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("310000","上海市","0")'>南宁</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("500000","重庆市","0")'>东莞</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("440100","广东省广州市","440000")'>广州市</el-link>
              <el-link style='padding:0 3px;' :underline="false" v-on:click.prevent='selectRegion("440300","广东省深圳市","440000")'>深圳市</el-link>
            </div>
            <div>
              <el-link style='padding:0 8px;' :underline="false" v-on:click.prevent='goAnchor(".r_"+item)' v-for="item in alphabet" :key="item">{{item}}</el-link>
            </div>
          </div>
            <el-table
            class="scroll_cls" 
              :data="regionList"
              style="width: 100%"
              max-height='350px'
              :span-method="objectSpanMethod"
              :show-header='false' 
              :row-class-name='rowKeyFun'
              >
              <el-table-column
                prop="chineseToEn"
                label="字母"
                width="40">
              </el-table-column>
              <el-table-column
                prop="name"
                label="省"
                width="80">
                <template #default="scope">
                  <el-link :underline="false" v-on:click.prevent='selectRegion(PrefixInteger(scope.row.code, 6),scope.row.name,"0")'>{{scope.row.name}}</el-link>
                </template>
              </el-table-column>
              <el-table-column
                prop="children"
                label="市">
                <template #default="scope">
                  <div v-if=" ',11,12,31,50,'.indexOf(''+scope.row.code+',')<0">
                    <el-link style='padding:0 3px;' :underline="false" v-for="child in scope.row.children" :key="child.code" v-on:click.prevent='selectRegion(PrefixInteger(child.code, 6),scope.row.name+child.name,scope.row.code)' >{{child.name}}</el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
        </div>
      </el-popover>
      </div>
      <!-- 时间列表 -->
      <el-dropdown @command="selectDate">
        <el-button type="primary" style="width:100px;">
          {{ new Date(currentDate).getFullYear()}}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="item in dateList" :key="item.date" :command="item.date" >{{new Date(item.date).getFullYear()}}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown >
    </div>
  <!-- 经济舆情 -->
  <div class="card-f">
    <div class="para-title" style=" position:absolute;">
          <span>
            <svg class="icon para-title-i" aria-hidden="true" style="font-size:24px;">
              <use xlink:href="#ec-icon-zichan"></use>
            </svg>
            <span class="para-title-text">经济舆情</span>
          </span>
    </div>
    <eco-article-scroll :regionCode='region.code'></eco-article-scroll>
  </div>
  <!-- 地图地区Tooltip -->
  <div ref='mapMarker' class="tipMarker top"></div>
  </div>
  <!-- 地图色阶 -->
  <ul class="ul-cl">
      <li v-for="(item,index) in colorLevel" :key="index"><span class="span-cl" :style="{background:colors[index]}"></span><span>{{index==0?">"+item:colorLevel[index-1]+"~"+item}}</span></li>
      <li v-if="colorLevel?.length>1"><span class="span-cl" :style="{background:colors[5]}"></span><span>&lt;{{ colorLevel[4]}}</span></li>
  </ul>
  <Footer style="position: absolute;bottom: 0px;"></Footer>
</template>

<script>
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import { onMounted, reactive, ref,onBeforeUnmount} from 'vue'
import {useRegionList} from '@/http/homeApi'
import {getCurRegionItemData,getLevelReginCurItemData} from '@/http/ecoApi'
import {getRegionInfo} from '@/http/basicsApi'
//import * as echarts from 'echarts'
import {scrollTo} from '@/utils/scroll-to'
import {PrefixInteger} from '@/utils/index'
import Header from '@/components/Header'
import EcoArticleScroll from '@/components/EcoArticleScroll'
import Footer from '@/components/Footer'
import { ElLoading } from 'element-plus'
import CountTo from '@/components/countTo/CountTo.vue'
import { useRoute } from 'vue-router'
export default {
  name: 'Home',
  components:{
    Header,
    EcoArticleScroll,
    Footer,
    CountTo
  },
  setup(){
    //加载高德地图
    let map;
    let districtExplorer=null;
    const mapMarker=ref(null);
    //当前时间
    let currentDate=ref(new Date().getMonth()>2?(new Date().getFullYear()-1)+"-01-01":(new Date().getFullYear()-2)+"-01-01");
    let dateList=reactive([]);//时间列表
    for (var i = 0, len = 5; i < len; i++) {
      dateList.push({date:new Date(currentDate.value).getFullYear()-i+"-01-01"});            
    }
    //选中地区信息
    const route=useRoute();
    console.log(route.query.regionCode);
    let region=reactive({
      code:route.query.regionCode,
      name:'',
      parent:'',
    })
    
    //获取地区的信息
    const getRegionInfoFun=()=>{
      if(!region.code || region.code=='00')
      {
        region.code='110000';
      }
      localStorage.setItem("regionCode",region.code); //选中的地区保存到localStorage，已在【区域态势】等模块获取选中的地区
      //console.log(region.code);
      getRegionInfo(region.code).then((res)=>{
        if(res.code==20000){
          region.name=res.data.fullTitle
          region.parent=res.data.parent
        }else{//未查询到IP地区基本信息，默认北京市
          region.code='110000';
          region.name='北京市';
          region.parent='0'
        }
        //获取当前时间地区指标项值
        getCurRegionItemDataFun();
        //地图显示
        getLevelReginCurItemDataFun();
      });
      
    }
    //高德地图初始化
    const initAMap=()=>{
        map=new AMap.Map("mapContainer",{ // eslint-disable-line no-unused-vars
           zoom: 5, //级别
          center: [116.397428, 39.90923], //中心点坐标
          //viewMode:'3D',                                   //使用3D视图
          mapStyle: "amap://styles/grey", //设置地图的显示样式mapStyle: 'amap://styles/whitesmoke', //设置地图的显示样式
        })
        
        AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$'], function (DistrictExplorer, $) {// eslint-disable-line no-unused-vars
            //创建一个实例
            districtExplorer = window.districtExplorer = new DistrictExplorer({
                eventSupport: true, //打开事件支持
                map: map
            });
            //鼠标hover提示内容
            var tipMarker = new AMap.Marker({
                content:mapMarker.value,
                offset: new AMap.Pixel(0, 0),
                bubble: true
            });
            //根据Hover状态设置相关样式
            function toggleHoverFeature(feature, isHover, position) {
                tipMarker.setMap(isHover ? map : null);

                if (!feature) {
                    return;
                }
                var props = feature.properties;
                
                if (isHover) {
                    let item=curRegionItemData.filter(t=>{return t.isSel==true})[0];
                    let itemName=item.name;
                    let itemUnit=item.unit;
                    let valStr="-";
                    let filterArray = curChildRegionItemData.value.filter((e)=> { return e.regionCode ==props.adcode; });
                    if(filterArray.length > 0 && filterArray[0]['itemValue']!=null){
                      valStr=filterArray[0]['itemValue'].toFixed(2)+(filterArray[0]['isForecast']==true?'E':'')+itemUnit;
                    }
                    mapMarker.value.innerHTML=props.name + '(' + new Date(currentDate.value).getFullYear() + '年'  + ')<br/>'+itemName+'：'+ valStr;
                    //更新位置
                    tipMarker.setPosition(position || props.center);
                }
                //更新相关多边形的样式
                
                var polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode);
                for (var i = 0, len = polys.length; i < len; i++) {
                    polys[i].setOptions({
                        fillOpacity: isHover ? 1 : 0.7
                    });
                }
            }
            //监听feature的hover事件
            districtExplorer.on('featureMouseout featureMouseover', function (e, feature) {
                toggleHoverFeature(feature, e.type === 'featureMouseover',
                    e.originalEvent ? e.originalEvent.lnglat : null);
            });

            //监听鼠标在feature上滑动
            districtExplorer.on('featureMousemove', function (e, feature) {// eslint-disable-line no-unused-vars
                //更新提示位置
                tipMarker.setPosition(e.originalEvent.lnglat);
            });
            /*
            //feature被点击
            districtExplorer.on('featureClick', function (e, feature) {
                var props = feature.properties;
                //如果存在子节点
                if (props.childrenNum > 0 ) {
                    //切换聚焦区域
                    let regionGrade=props.acroutes.length;
                    selectRegion(props.adcode, props.name, props.acroutes[regionGrade-1],regionGrade);
                }
            });

            //外部区域被点击
            districtExplorer.on('outsideClick', function (e) {

                districtExplorer.locatePosition(e.originalEvent.lnglat, function (error, routeFeatures) {

                    if (routeFeatures && routeFeatures.length > 1) {
                        //切换到省级区域
                        selectRegion(routeFeatures[1].properties.adcode, routeFeatures[1].properties.name, '0','1');

                    } else {
                        //切换到全国
                        selectRegion('100000', '全国','','0');
                    }

                }, {
                    levelLimit: 2
                });
            });
            */
            //地图显示
            //getLevelReginCurItemDataFun();
        });
    };
    
    //地区选择，地图切换区域
    const selectRegion=(code,name,parent)=>{
      localStorage.setItem("regionCode",code); 
      region.code=code;
      region.name=name;
      region.parent=parent;
      regionListVisible.value=false;
      //获取当前时间地区指标项值
      getCurRegionItemDataFun();
      //地图显示
      //getLevelReginCurItemDataFun();
      loadAreaNode(mapRegonArr.value, function (error, areaNode) {// eslint-disable-line no-unused-vars
          if (error) {
              return;
          }
        });
    }
    //地图加载区域
    function loadAreaNode(adcodes, callback) {
      //console.log(adcodes);
      districtExplorer.loadMultiAreaNodes(adcodes, function (error, areaNodes) {
        if (error) {
          if (callback) {
              callback(error);
          }
          //console.error(error);
          return;
        }
        if (callback) {
            callback(null, areaNodes);
        }
        //设置定位节点，支持鼠标位置识别
        //注意节点的顺序，前面的高优先级
        districtExplorer.setAreaNodesForLocating(areaNodes);
        //清除已有的绘制内容
        districtExplorer.clearFeaturePolygons();
         for (var i = 0, len = areaNodes.length; i < len; i++) {
                renderAreaPolygons(areaNodes[i]);
            }
        
        //更新地图视野
        //map.setFitView(districtExplorer.getAllFeaturePolygons());
        let areaNode=areaNodes.filter(((e)=> { return e.adcode ==(region.parent=='0'?region.code:region.parent); }))[0];
        //map.setBounds(areaNode.getBounds(), null, null, true);
        //console.log( areaNode.getIdealZoom());
        if(region.code==areaNode.adcode){
          map.setCenter(areaNode._data.geoData.parent.properties.center); //设置地图中心点
        }
        else{
          let subAreaNode= areaNode._data.geoData.sub.features.filter(t=>t.properties.adcode==region.code)[0];
          //console.log(subAreaNode);
          map.setCenter(subAreaNode.properties.center); //设置地图中心点
        }
      
        //console.log(areaNode.adcode);
        if(region.parent=='' || region.parent=='0'){
          map.setZoom(areaNode.getIdealZoom()-1);
        }
        else{
          map.setZoom(areaNode.getIdealZoom());
        }
      });

    }
 
    function renderAreaPolygons(areaNode) {

      //绘制子区域
      districtExplorer.renderSubFeatures(areaNode, function (feature, i) {// eslint-disable-line no-unused-vars
        let {fillColor}= getFillColorFun(feature.properties.adcode);
        return {
            cursor: 'default',
            bubble: true,
            strokeColor:feature.properties.adcode==region.code? '#e6a23c':fillColor?fillColor:"#3366cc", //线颜色 fillColor?fillColor:"#3366cc", //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: feature.properties.adcode==region.code? 2:0.5, //线宽
            fillColor: fillColor, //填充色
            fillOpacity:0.7, //填充透明度
          };
        });
        //绘制父区域
        districtExplorer.renderParentFeature(areaNode, {
            cursor: 'default',
            bubble: true,
            strokeColor: areaNode.adcode==region.code? '#e6a23c':"#3366cc", //线颜色
            strokeOpacity:1, //线透明度
            strokeWeight: areaNode.adcode==region.code? 2:0, //线宽
            fillColor: null, //填充色
            fillOpacity: 0, //填充透明度
        }
      );
    }
    //获取地图地区的透明度
    const getFillColorFun=(adcode)=>{
      let fillColor=null;
      
        //把数值分为六段色阶（4%，11%，23%，42%，70%）
        var filterArray = curChildRegionItemData.value.filter((e)=> { return e.regionCode ==adcode; });
        if(filterArray && filterArray.length>0 && filterArray[0]['itemValue'] && colorLevel.value.length>0){
          let val=filterArray[0]['itemValue'];
          if(val>=colorLevel.value[0]){
            fillColor=colors[0];
          }
          else if(val>=colorLevel.value[1]){
            fillColor=colors[1];
          }
          else if(val>=colorLevel.value[2]){
            fillColor=colors[2];
          }
          else if(val>=colorLevel.value[3]){
            fillColor=colors[3];
          }
          else if(val>=colorLevel.value[4]){
            fillColor=colors[4]
          }
          else{
            fillColor=colors[5]
          }
        }  
        return{fillColor}




      // var filterArray = curChildRegionItemData.value.filter((e)=> { return e.regionCode ==adcode; });
      // let fillOpacityVal=0;
      // if(filterArray && filterArray.length>0){
      //   if(filterArray[0]['itemValue'] ){//指标项有值
      //      fillOpacityVal= (curRegionChildNum.value-filterArray[0]['ranking'])/curRegionChildNum.value;
      //      fillOpacityVal= fillOpacityVal<0.1?0.1:fillOpacityVal;//透明度最小为0.1，没有值时为0
      //   }
      //   else if(!filterArray[0]['itemValue']){
      //     fillOpacityVal=0;
      //   }
      // }
      //return {fillOpacityVal,filterArray};
    }
    
  
    let regionListVisible=ref(false);//地区列表弹出框是否显示
    
    //单击指标项
    const selItemFun=(id)=>{
      //去掉选中
      curRegionItemData.filter(t=>{return t.isSel==true})[0].isSel=false;
      //重新选中
      curRegionItemData.filter(t=>{return t.id==id})[0].isSel=true;
      //地图显示
      getLevelReginCurItemDataFun();
    }
    
    //选择时间
    const selectDate=(date)=>{
      currentDate.value=date;
      //获取当前时间地区指标项值
      getCurRegionItemDataFun();
      //地图显示
      getLevelReginCurItemDataFun();
    }
    //获取地图数据（等级地区指标项数据）
    const curChildRegionItemData=ref(null);
    let mapRegonArr=ref([]);//地图的地区
    let colorLevel=ref([]);//色阶数组
    const colors =reactive(['rgb(207,1,73)','rgb(233,90,22)','rgb(255,211,32)','rgb(171,203,32)','rgb(0,151,99)','rgb(3,151,239)']);
        
    const getLevelReginCurItemDataFun=async()=>{
      colorLevel.value=[];//清空
      const params={
        regionCode:region.code=="100000"?"0":region.parent=='0'?region.code:region.parent,
        date:currentDate.value,
        itemId:curRegionItemData.filter(t=>{return t.isSel==true})[0].id,
        isForecast:true
      }
      //加载等级
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.1)',
      })
      //console.log(params);
      const result=await getLevelReginCurItemData(params);
      //console.log(result);
      if(result.code==20000){
        curChildRegionItemData.value=result.data.dataList;
        //色阶
        //如果没有传入值，Math.max()(返回传入值中的最大值)将返回 -Infinity，Math.min()(返回传入值中的最小值)将返回Infinity。
        let maxVal=Math.max.apply(Math, result.data.dataList.map(item=>item.itemValue).filter(item=>item))//指标项值最大值
        let minVal=Math.min.apply(Math, result.data.dataList.map(item=>item.itemValue).filter(item=>item))//指标项值最小值
        //把数值分为六段色阶
        //把数值分为六段色阶
        if(!Object.is(-Infinity, maxVal) || !Object.is(Infinity, minVal)){
          let val=maxVal-minVal;
          if(val==0){//只有一个值或者都是一个数值
             colorLevel.value.push(val);
          }
          else{
            colorLevel.value.push(Math.floor(val*0.75+minVal));//Math.floor(x)向下舍入
            colorLevel.value.push(Math.floor(val*0.50+minVal));
            colorLevel.value.push(Math.floor(val*0.30+minVal));
            colorLevel.value.push(Math.floor(val*0.15+minVal));
            colorLevel.value.push(Math.floor(val*0.05+minVal));
          }
        }
        if(curRegionItemData.filter(t=>{return t.isSel==true})[0].id=='20002724'){//人均GDP
          colorLevel.value=[260000,130000,95290,52000,26000];
        }
        //地区父级+港澳台地区
        mapRegonArr.value=[...new Set(result.data.dataList.map(t=>{return t.parentCode})),...['820000','810000','710000']];//获取地区父级并去重
        loadAreaNode(mapRegonArr.value, function (error, areaNode) {// eslint-disable-line no-unused-vars
          if (error) {
              return;
          }
        });
      }
      else{
        curChildRegionItemData.value=null;
      }
      loading.close();//关闭加载等级
    }
    //获取当前时间地区指标项值
    let curRegionItemData=reactive([
      {id:'20002709',name:'GDP',value:'-',unit:'',isForecast:'',isSel:true},
      {id:'20002724',name:'人均GDP',value:'-',unit:'',isForecast:'',isSel:false},//人均GDP
      {id:'20010507',name:'固投增速',value:'-',unit:'',isForecast:'',isSel:false},//固定资产投资
      {id:'20000848',name:'社消总额',value:'-',unit:'',isForecast:'',isSel:false},//社会消费品零售总额
      {id:'20000009',name:'财政收入',value:'-',unit:'',isForecast:'',isSel:false},//财政收入
      {id:'20000922',name:'城镇人均可支配收入',value:'-',unit:'',isForecast:'',isSel:false},//人均收入
      {id:'20002696',name:'进出口总额',value:'-',unit:'',isForecast:'',isSel:false},//进出口总额
      // {id:'20009796',name:'城镇化率',value:'-',unit:'',isForecast:'',isSel:false},//城镇化率
      {id:'20000998',name:'年末常住人口',value:'-',unit:'',isForecast:'',isSel:false},//年末常住人口
    ]);
    //获取地区当前指标数据
    const getCurRegionItemDataFun= async()=>{
      const params={
        regionCode:region.code,
        date:currentDate.value,
        itemId: curRegionItemData.map(t=>{return t.id}).toString(),
        isForecast:true
      }
      //console.log(params);
      const result= await getCurRegionItemData(params);//当前时间地区指标项值
      //console.log(result);
      if( result.code==20000){
        curRegionItemData.forEach((item)=>{
          var filterAry=result.data.filter(t=>t.itemId==item.id);
          if(filterAry && filterAry.length>0)
          {
            item.value=filterAry[0].itemValue==null?'-':filterAry[0].itemValue;
            item.unit=filterAry[0].itemUnit;
            item.isForecast=filterAry[0].isForecast?"E":"";
          }
        });
      }
      else{
        curRegionItemData.value=null;
      }

    }
    
    //地区列表
    const{regionList,alphabet}= useRegionList();
    //地区列表————table 合并列
    let prevSpanObj='-1';
    const objectSpanMethod=({ row, column, rowIndex, columnIndex })=> {// eslint-disable-line no-unused-vars
      if (columnIndex === 0) {
        //首先要确定以字母字段进行排序，过滤地区列表，
        let filterArrary=regionList.value.filter((item)=>{
          return item.chineseToEn==row.chineseToEn;
        })
        if (prevSpanObj != row.chineseToEn) {
          prevSpanObj=row.chineseToEn;
          return {
            rowspan: filterArrary.length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
    //地区列表————给行绑定不一样的样式，为了锚点定位
    let prevClass='-1';
    const rowKeyFun=(row)=>{
      if(prevClass!=row.row.chineseToEn){
        prevClass=row.row.chineseToEn;
        return 'r_'+row.row.chineseToEn;
      }
      else{
        return ''
      }
      
    }
    //地区列表————锚点跳转
    const goAnchor= (selector) => {
        let to=document.querySelector(selector).offsetTop;
        scrollTo('.el-table__body-wrapper',to,800);
    }
    //自适应
    let listener=()=> {
    }
    window.addEventListener('resize', listener)//监听resize事件
    onMounted(()=>{
      //加载高德地图
      initAMap();
      //加载数据
      getRegionInfoFun();
      //加载当前时间地区经济指标数据
      //getCurRegionItemDataFun();
     
    })
    onBeforeUnmount(()=>{//销毁之前
      //clearInterval(timer);
      window.removeEventListener('resize',listener)
    })
    return{
      //dom对象
      mapMarker,
      regionList,
      alphabet,
      regionListVisible,
      region,
      currentDate,
      dateList,
      selectDate,
      selectRegion,
      curRegionItemData,
      selItemFun,
      colorLevel,
      colors,
      objectSpanMethod,
      rowKeyFun,
      goAnchor,
      PrefixInteger,
      listener
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
#app {
    height:calc(100% + 40px) !important;
}
  #mapContainer{
    position: fixed;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
  }
  .nav_box{
    position: relative;
  }
  .card-f{
    position:absolute;
    bottom: 28px;
    right: 0px;
    left: 0px;
    background-color: rgba(14, 35, 60, 0.5);
  }
  .para-title {
    line-height: 50px;
    font-size: 1.4rem;
    font-weight: 400;
   div{
     position: absolute;
     left: 130px;
     right: 50px;
     top:10px;
     margin:0;
     height: 1px;
     border-top:1px solid $specialtext-color;
   }
   .para-title-i{
     margin-right: 6px;
   }
   .para-title-text{
     color: $specialtext-color;
     font-weight: 600;
   }
  }
  
  .bg-purple{
    color: $specialtext-color;
    height: 30px;
    margin-bottom: 6px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
  }
  .grid-content{
    background-color: $bgTransColor;
    height: 30px;
    margin-bottom: 6px;
    text-align: center;
    line-height: 30px;
    border-radius: 2px;
    font-size: 14px;
    .item{
      font-size: 12px;
    }
  }
  .tipMarker.top {
      transform: translate(-50%,-110%);
  }
  .tipMarker {
      color: #555;
      background-color: rgba(63, 148, 253, 0.3);
      border: 1px solid rgba(63, 148, 253, 0.3);
      border-radius: 5px;
      padding: 6px 10px;
      font-size: 14px;
      color:#fff;
      white-space: nowrap;
      display: inline-block;
  }
  //左箭头样式
  .icon-caret-left-1{
    width: 12px;
    color: #2F3A5B;
  }
  .icon-caret-left-2{
    color: $specialtext-color;
  }
  .econoMsg{
    height: 200px;
    overflow: hidden;
  }
  .list-flex{
    display: flex;
    align-items: center;
    border-radius: 2px;
    min-height: 30px;
    margin: 0;
    margin-bottom: 6px;
    cursor: pointer;
    span{
      padding:0 8px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      display: inline-block;
      min-width: 100px;
    }
    &:hover{
       background: $bgTransColor;
    }
  }
  .city_content_medium {
    padding-bottom: 6px;
    border-bottom: 1px solid #606266;
}
.r-card{
  display:flex;
  align-items:stretch;
  margin-bottom: 3px;
  background: $bgTransColor;
  border-radius: 2px;
  .r-card-t{
    background:$specialtext-color;
    padding:0px 6px;
    line-height:36px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .r-card-v{
    padding: 3px 12px;
    text-align: center;
    .pre-text{
      color: #6BDBA1;
    }
  }
}
.col-d{
    background: rgba(14,35,60,.5);
    border:1px solid rgba(63,148,253,.1);
    border-radius: 6px;
    padding: 6px 10px;
    position:relative;
    overflow:hidden;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    i{
      margin-left: 6px;
    }
    p{
      color: gold;
      background-color: rgba(0,0,0,.5);
      text-align: center;
      padding:6px;
      margin: 6px 0;
      border-radius: 2px;
      height: 35px;
    }
    strong{
      font-size: 20px;
      font-weight: 300;
    }
    
   /*  &:after{
			position: absolute;
			left: -100%;                    /*改变left的值，让其相对box影藏* /
			top: 0;
			width: 30%;
			height: 100%;
			content: "";
			/* Safari 5.1 - 6.0 * /
      background: -webkit-linear-gradient(left,rgba(255,255,255,0) 0,rgba(255,255,255,.3) 50%,rgba(255,255,255,0) 100%);
      /* Opera 11.1 - 12.0 * /
      background: -o-linear-gradient(left,rgba(255,255,255,0) 0,rgba(255,255,255,.3) 50%,rgba(255,255,255,0) 100%);
      /* Firefox 3.6 - 15 * /
      background: -moz-linear-gradient(left,rgba(255,255,255,0) 0,rgba(255,255,255,.3) 50%,rgba(255,255,255,0) 100%);
      /* 标准的语法 * /
      background: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 50%,rgba(255,255,255,0) 100%);
      transform: skewX(-45deg);
		} */
    &:hover{
      background-color: rgba(63,148,253,.1);
		}
		/* &:hover:after{
			left: 150%;
      transition: 2s ease;
		} */

  }
.col-d.selcol{
  background: rgba(63,148,253,.6);
  border:1px solid rgba(63, 148, 253, 0.6);
}
.ul-cl{
  position: absolute;
  left: 20px;
  bottom: 100px;
  background: rgba(0,0,0,0.1);
  padding: 10px 20px;
  color:#fff;
  li{
    line-height: 25px;
  .span-cl{
    width: 12px;
    height:12px;
    margin-right: 6px;
    border-radius: 2px;
    border:1px solid;
    vertical-align: middle;
    display: inline-block;
    opacity: 0.7;
  }
  }
}
.txt{
  color: $specialtext-color;
  display: inline-block;
  width: 100%;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
</style>
