<template>
    <div id="footer">
        Copyright ©2022 北京易可诺信息科技有限公司&nbsp;&nbsp;
        <a href="https://beian.miit.gov.cn/publish/query/indexFirst.action" target="_blank" style="color:inherit;">京ICP备18038674号</a>
         <span class="span-ip">IP：{{cname}}</span>
    </div>
</template>
<script>
export default {
    setup() {
        const cname=localStorage.cname?localStorage.cname:'北京市'
        return{
            cname,
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
#footer{
    padding-left: 10px;
    line-height: 30px;
    height: 30px;
    text-align: left;
    color: #8D9196;
    .span-ip{
        margin-left: 20px;
        border: 1px solid rgba(63, 148, 253, 0.1);
        border-radius: 2px;
        padding: 2px 5px;
    }
}
</style>