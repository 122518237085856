
import $http from './index'
//指标等级
export const getGradeList=()=>{
  return $http.get(`/api/Economy/GetGradeList`,);
}
//指标周期
export const getRenewalCycleList=()=>{
  return $http.get(`/api/Economy/GetRenewalCycleList`,);
}
// //获取当前时间地区指标项值
// export const getCurRegionItemsData=(data)=>{
//   return $http.post(`/api/EcoAnalysis/GetReginCurItemsData`,data);
// }
//获取地区的经济活力
export const getRegionEcoVitality=(data)=>{
  return $http.post(`/api/EcoAnalysis/getRegionEcoVitality`,data);
}
//获取当前时间地区指标项值、增速、排名
export const getCurRegionItemData=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetReginCurItemData`,data);
}
//获取某等级地区某时间的指标数据(高德地图)
export const getLevelReginCurItemData=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetLevelReginCurItemData`,data);
}  

//获取经济舆情的数据量
export const getEcoArticleNum=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetEcoArticleNum`,data);
}
//获取具体经济舆情的详细信息
export const getRegionEcoArticleNum=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionEcoArticleNum`,data);
}


//搜索指标项名为条件获取宏观、行业、微观、舆情的指标数据量
export const getItemNumOfClass=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemNumOfClass`,data);
}
//获取更新周期分类的指标数据量
export const getItemNumOfCycle=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemNumOfCycle`,data);
}
//获取宏观经济的指标数量列表
export const getItemNumList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemNumList`,data);
}
//获取宏观经济的地区指标数量列表
export const getRegionItemNumList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemNum`,data);
}
//获取地区指标历史数据
export const getRegionItemDataList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemDataList`,data);
}
//获取宏观经济地区指标图表
export const getRegionItemDataOfChart=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemDataOfChart`,data);
}
//获取指标项信息
export const getItemInfo=(itemId)=>{
  return $http.get(`/api/Economy/GetItemInfo?itemId=${itemId}`);
}
//获取指标列表
export const getItemList=(data)=>{
  return $http.post(`/api/Economy/GetItemList`,data);
}
//获取区域比较的默认三个城市（城市+四个直辖市）
export const getRegionOfDifference=(regionCode,date)=>{
  return $http.get(`/api/EcoAnalysis/GetRegionOfDifference?regionCode=${regionCode}&date=${date}`);
}
//地区的指标增速排名
export const getRegionSpeedSort=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionSpeedSort`,data);
}
//获取下级地区当前时间的指标数据
export const getNextReginCurItemData=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetNextReginCurItemData`,data);
}
//获取最新时间
export const getCurrentDate=(data)=>{
  return $http.post(`/api/Economy/GetCurrentDate`,data);
}



//行业经济
//获取指标列表
export const getItemListOfEcoIndustry=(data)=>{
  return $http.post(`/api/EcoIndustry/GetItemList`,data);
}
//获取行业经济的指标数量列表
export const getItemNumListOfEcoIndustry=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemNumListOfEcoIndustry`,data);
}
//获取行业经济的地区指标数量列表
export const getRegionItemNumOfEcoIndustry=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemNumOfEcoIndustry`,data);
}
//获取行业经济的行业分类指标数量列表
export const getEcoIndustryClassItemNum=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetEcoIndustryClassItemNum`,data);
}
//获取指标项信息
export const getItemInfoOfEcoIndustry=(itemId)=>{
  return $http.get(`/api/EcoIndustry/GetItemInfo?itemId=${itemId}`);
}
//获取地区指标历史数据
export const getItemDataListOfEcoIndustry=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemDataListOfEcoIndustry`,data);
}
//获取宏观经济地区指标图表
export const getEcoIndustryItemData_chart=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetEcoIndustryItemData_Chart`,data);
}
//获取某行业某地区某时间某指标值（多行业同等级地区同更新周期指标）
export const getCurItemData=(data)=>{
  return $http.post(`/api/EcoIndustry/GetCurItemData`,data);
}
//获取地区行业指标数据（多地区多行业多指标）
export const getRegionIndustryDataList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionIndustryDataList`,data);
}
//获取下级地区行业指标数据（多地区一行业一指标项）
export const getNextRegionIndustryDataList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetNextRegionIndustryDataList`,data);
}
//行业报告
export const getEcoIndustryReportList=(data)=>{
  return $http.post(`/api/EcoIndustry/GetReportList`,data);
}
//获取最新时间
export const getEcoIndustryCurrentDate=(data)=>{
  return $http.post(`/api/EcoIndustry/GetCurrentDate`,data);
}
// export const getItemEcoIndustryMappingList=(data)=>{
//   return $http.post(`/api/EcoIndustry/GetItemEcoIndustryMappingList`,data);
// }




//微观经济
//获取指标项的基本信息
export const getItemInfoOfMicroEco=(itemId)=>{
  return $http.get(`/api/MicroEco/GetItemInfo?itemId=${itemId}`);
}
//获取产品列表
export const getProductList=(data)=>{
  return $http.post(`/api/MicroEco/GetProductList`,data);
}
//获取产品的基本信息
export const getProductInfo=(proId)=>{
  return $http.get(`/api/MicroEco/GetProductInfo?proId=${proId}`);
}
//房价、招聘、物价的指标数据量列表

export const getClassItemNumOfMicroEco=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetClassItemNumOfMicroEco`,data);
}
//获取房价的指标数据量列表
export const getItemNumListOfHouse=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemNumListOfHouse`,data);
}
//获取招聘的指标数据量列表
export const getItemNumListOfWork=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemNumListOfWork`,data);
}
//获取产品指标数据量列表
export const getProductNumList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetProductNumList`,data);
}
//获取地区指标数据量列表
export const getRegionItemNumListOfMicroEco=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemNumListOfMicroEco`,data);
}
//获取某产品的地区指标项数据量列表
export const getRegionItemNumListOfProduct=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemNumListOfProduct`,data);
}
//获取招聘的职位的指标数据量列表
export const getPosItemNumList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetPosItemNumList`,data);
}
//获取房价数据
export const getHouseDataList=(data)=>{
  return $http.post(`/api/MicroEco/GetHouseDataList`,data);
}
//获取招聘数据
export const getWorkDataList=(data)=>{
  return $http.post(`/api/MicroEco/GetWorkDataList`,data);
}
//获取物价数据
export const getProductDataList=(data)=>{
  return $http.post(`/api/MicroEco/GetProductDataList`,data);
}
//房价数据（图表）
export const getHouseDataList_chart=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetHouseDataList_Chart`,data);
}
//招聘数据（图表）
export const getWorkDataList_chart=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetWorkDataList_Chart`,data);
}
//物价数据（图表）
export const getProductDataList_chart=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetProductDataList_Chart`,data);
}

//区域经济
//获取区域经济的主题列表_通用
export const getEcoThemeList=(regionCode)=>{
  return $http.get(`/api/EcoAnalysis/GetEcoThemeList?regionCode=${regionCode}`);
}
//根据用户及地区,判断是否存在个性主题
export const getEcoThemeNumSelf=(regionCode)=>{
  return $http.get(`/api/EcoAnalysis/GetEcoThemeNumSelf?regionCode=${regionCode}`);
}
//获取当前用户的区域经济的主题列表_个性
export const getEcoThemeListSelf=(regionCode)=>{
  return $http.get(`/api/EcoAnalysis/GetEcoThemeListSelf?regionCode=${regionCode}`);
}
export const getAreaEcoListOfTheme=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetAreaEcoListOfTheme`,data);
}
//获取地区指标排名（同级地区一指标项）
export const getRegionItemSort=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemSort`,data);
}
//地区的指标项占比（一地区多指标）
export const getRegionItemPie=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemPie`,data);
}
//获取地区的指标历史数据（一地区多指标）
export const getRegionItemLine=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetRegionItemLine`,data);
}


//获取地区指标数据（多地区多指标）
export const getMultiRegionItemDataList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetMultiRegionItemDataList`,data);
}


